import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { AuthProvider } from '@/providers/auth-provider';
import { ProtectedRoute } from '@/components/protected-route';
import { VersionInfo } from '@/components/version-info';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Pages
import { LoginPage } from '@/pages/login';
import Dashboard from '@/pages/Dashboard';
import { ConfigsPage } from '@/pages/configs';
import { JobsPage } from '@/pages/jobs';
import { ResultsPage } from '@/pages/results';

// Create a client
const queryClient = new QueryClient();

export default function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/configs" element={<ProtectedRoute><ConfigsPage /></ProtectedRoute>} />
              <Route path="/jobs" element={<ProtectedRoute><JobsPage /></ProtectedRoute>} />
              <Route path="/results" element={<ProtectedRoute><ResultsPage /></ProtectedRoute>} />
            </Routes>
            <Toaster />
            <VersionInfo />
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}