import type { APIError } from '@/types/api.js';

const handleError = (error: unknown): APIError => {
  if (error && typeof error === 'object' && 'error' in error) {
    const apiError = error.error as { message?: string; code?: string; statusCode?: number };
    return {
      message: apiError.message || 'Request failed',
      code: apiError.code || 'UNKNOWN_ERROR',
      statusCode: apiError.statusCode || 500
    };
  }
  return {
    message: 'Request failed',
    code: 'UNKNOWN_ERROR',
    statusCode: 500
  };
};

export const get = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    ...init,
  });
  if (!response.ok) {
    throw handleError(await response.json());
  }
  return response.json();
};

export const post = async <T>(url: string, data?: unknown, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include',
    ...init,
  });
  if (!response.ok) {
    throw handleError(await response.json());
  }
  return response.json();
};

export const put = async <T>(url: string, data: unknown, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    credentials: 'include',
    ...init,
  });
  if (!response.ok) {
    throw handleError(await response.json());
  }
  return response.json();
};

export const del = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    ...init,
  });
  if (!response.ok) {
    throw handleError(await response.json());
  }
  return response.json();
};