import { useState } from 'react'
import { Button } from '@/components/ui/button.js'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.js'
import { Input } from '@/components/ui/input.js'
import { Label } from '@/components/ui/label.js'
import { Textarea } from '@/components/ui/textarea.js'
import type { Field } from '@/types/scraping.js'
import { useScrapingConfigs } from '@/hooks/use-scraping-configs.js'
import { useToast } from '@/hooks/use-toast.js'

export default function Dashboard() {
  const { addConfig } = useScrapingConfigs()
  const { toast } = useToast()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [fields, setFields] = useState<Field[]>([
    { name: '', description: '' }
  ])

  const handleFieldChange = (index: number, updates: Partial<Field>) => {
    setFields(prev => {
      const newFields = [...prev]
      newFields[index] = { ...newFields[index], ...updates }
      return newFields
    })
  }

  const handleAddField = () => {
    setFields(prev => [...prev, { name: '', description: '' }])
  }

  const handleRemoveField = (index: number) => {
    setFields(prev => prev.filter((_, i) => i !== index))
  }

  const handleSubmit = async () => {
    try {
      const config = {
        name,
        description,
        url,
        urlPattern: url,
        fields: fields.filter(f => f.name.trim()),
        selectors: {},
        user_id: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }

      await addConfig(config)
      toast({
        title: 'Success',
        description: 'Configuration created successfully',
      })

      setName('')
      setDescription('')
      setUrl('')
      setFields([{ name: '', description: '' }])
    } catch (error) {
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to create configuration',
        variant: 'destructive',
      })
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Create Scraping Configuration</CardTitle>
        <CardDescription>
          Define what data you want to extract from web pages
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter configuration name"
            />
          </div>

          <div>
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter configuration description"
            />
          </div>

          <div>
            <Label htmlFor="url">URL Pattern</Label>
            <Input
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter URL pattern to match"
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <Label>Fields</Label>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={handleAddField}
              >
                Add Field
              </Button>
            </div>

            {fields.map((field, index) => (
              <div key={index} className="space-y-4">
                <div>
                  <Label htmlFor={`field-${index}-name`}>Field Name</Label>
                  <div className="flex gap-2">
                    <Input
                      id={`field-${index}-name`}
                      value={field.name}
                      onChange={(e) =>
                        handleFieldChange(index, { name: e.target.value })
                      }
                      placeholder="Enter field name"
                    />
                    {fields.length > 1 && (
                      <Button
                        type="button"
                        variant="destructive"
                        size="icon"
                        onClick={() => handleRemoveField(index)}
                      >
                        ×
                      </Button>
                    )}
                  </div>
                </div>

                <div>
                  <Label htmlFor={`field-${index}-description`}>
                    Field Description
                  </Label>
                  <Textarea
                    id={`field-${index}-description`}
                    value={field.description}
                    onChange={(e) =>
                      handleFieldChange(index, { description: e.target.value })
                    }
                    placeholder="Enter field description"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-end">
            <Button onClick={handleSubmit}>Create Configuration</Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
} 