import { useEffect } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useAuthContext } from '@/providers/auth-provider';
import { AuthErrorBoundary } from '@/components/auth/AuthErrorBoundary';
import { useToast } from '@/hooks/use-toast';
import { Loader2 } from 'lucide-react';

// Log environment variables on load (excluding secrets)
console.log('[Auth] Environment variables:', {
  VITE_WORKER_URL: import.meta.env.VITE_WORKER_URL,
  VITE_GOOGLE_REDIRECT_URI: import.meta.env.VITE_GOOGLE_REDIRECT_URI,
  VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  VITE_GOOGLE_APP_NAME: import.meta.env.VITE_GOOGLE_APP_NAME,
  VITE_GOOGLE_APP_DOMAIN: import.meta.env.VITE_GOOGLE_APP_DOMAIN,
});

export function LoginPage() {
  const { isAuthenticated, loading, login } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const code = searchParams.get('code');
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  useEffect(() => {
    if (error) {
      console.error('[Auth] OAuth error:', {
        error,
        description: errorDescription,
        params: Object.fromEntries(searchParams.entries())
      });
      toast({
        variant: 'destructive',
        title: 'Authentication Error',
        description: errorDescription || error === 'access_denied' 
          ? 'Access was denied. Please try again and grant the required permissions.'
          : `An error occurred during authentication: ${error}`,
      });
    } else if (code) {
      console.log('[Auth] Received OAuth code');
      login(code).catch((err) => {
        console.error('[Auth] Login error:', err);
        toast({
          variant: 'destructive',
          title: 'Authentication Failed',
          description: 'Failed to complete authentication. Please try again.',
        });
      });
    }
  }, [code, error, errorDescription, login, toast, searchParams]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  const handleLogin = () => {
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
    const scope = import.meta.env.VITE_GOOGLE_SCOPES || 'email profile openid';
    const state = crypto.randomUUID(); // Add CSRF protection
    const prompt = import.meta.env.VITE_GOOGLE_PROMPT || 'consent';
    const accessType = import.meta.env.VITE_GOOGLE_ACCESS_TYPE || 'online';
    
    // Validate required environment variables
    if (!clientId) {
      console.error('[Auth] Missing VITE_GOOGLE_CLIENT_ID');
      toast({
        variant: 'destructive',
        title: 'Configuration Error',
        description: 'Google OAuth client ID is not configured.',
      });
      return;
    }

    if (!redirectUri) {
      console.error('[Auth] Missing VITE_GOOGLE_REDIRECT_URI');
      toast({
        variant: 'destructive',
        title: 'Configuration Error',
        description: 'Google OAuth redirect URI is not configured.',
      });
      return;
    }
    
    console.log('[Auth] Starting OAuth flow', {
      clientId: clientId.substring(0, 8) + '...', // Log only the first 8 chars
      redirectUri,
      scope,
      prompt,
      accessType
    });
    
    const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
    authUrl.searchParams.append('client_id', clientId);
    authUrl.searchParams.append('redirect_uri', redirectUri);
    authUrl.searchParams.append('response_type', 'code');
    authUrl.searchParams.append('scope', scope);
    authUrl.searchParams.append('state', state);
    authUrl.searchParams.append('prompt', prompt);
    authUrl.searchParams.append('access_type', accessType);
    authUrl.searchParams.append('include_granted_scopes', 'true');
    
    // Add hosted domain if using G Suite
    const hostedDomain = import.meta.env.VITE_GOOGLE_APP_DOMAIN;
    if (hostedDomain) {
      authUrl.searchParams.append('hd', hostedDomain);
    }
    
    // Store state for verification when Google redirects back
    sessionStorage.setItem('oauth_state', state);
    
    console.log('[Auth] Redirecting to Google OAuth:', authUrl.toString());
    window.location.href = authUrl.toString();
  };

  return (
    <AuthErrorBoundary>
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="max-w-md w-full space-y-8 p-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">{import.meta.env.VITE_GOOGLE_APP_NAME || 'Xstra AI Scraper'}</h1>
            <p className="mt-2 text-muted-foreground">Sign in to continue</p>
          </div>
          <button
            onClick={handleLogin}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Sign in with Google
          </button>
          <p className="text-xs text-center text-muted-foreground mt-4">
            By signing in, you agree to our{' '}
            <a href={import.meta.env.VITE_GOOGLE_TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer" className="underline">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href={import.meta.env.VITE_GOOGLE_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer" className="underline">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </AuthErrorBoundary>
  );
} 