import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Button } from '../ui/button';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class AuthErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Auth error caught by boundary:', error, errorInfo);
  }

  private getErrorMessage(error: Error | null): string {
    if (!error) return 'An unexpected authentication error occurred';
    
    if (error.message.includes('redirect_uri_mismatch')) {
      return 'OAuth configuration error: Redirect URI mismatch. Please contact support.';
    }
    
    if (error.message.includes('invalid_client')) {
      return 'OAuth configuration error: Invalid client credentials. Please contact support.';
    }
    
    if (error.message.includes('access_denied')) {
      return 'Access was denied. Please try again and make sure to grant the required permissions.';
    }
    
    return error.message || 'An unexpected authentication error occurred';
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen p-4">
          <Alert variant="destructive" className="max-w-lg">
            <AlertTitle>Authentication Error</AlertTitle>
            <AlertDescription className="mt-2">
              {this.getErrorMessage(this.state.error)}
            </AlertDescription>
            <div className="flex gap-4 mt-4">
              <Button
                variant="outline"
                onClick={() => {
                  this.setState({ hasError: false, error: null });
                }}
              >
                Try again
              </Button>
              <Button
                variant="default"
                onClick={() => {
                  window.location.href = '/login';
                }}
              >
                Back to Login
              </Button>
            </div>
          </Alert>
        </div>
      );
    }

    return this.props.children;
  }
} 