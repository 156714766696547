import type { APIErrorResponse } from '@/types/api.js';

export async function handleResponse<T>(response: Response): Promise<T> {
  try {
    const data = await response.json();
    console.log('[API Response]', {
      url: response.url,
      status: response.status,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers.entries()),
      data
    });

    if (!response.ok) {
      const error = data as APIErrorResponse;
      const message = typeof error.error === 'string' ? error.error : 'An error occurred';
      console.error('[API Error]', {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        error: data
      });
      throw new Error(message);
    }
    return data as T;
  } catch (error) {
    console.error('[API Parse Error]', {
      url: response.url,
      status: response.status,
      statusText: response.statusText,
      error: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : undefined
    });
    throw error;
  }
}

export function handleAuthError() {
  console.error('[Auth Error] Redirecting to login');
  sessionStorage?.clear();
  location.href = '/login';
} 