import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleResponse } from '@/utils/browser-api.js';
import type { User } from '@/types/auth.js';

// Use the worker URL from environment variables
const API_BASE = import.meta.env.VITE_WORKER_URL || '';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const getSessionId = () => {
    const cookies = document.cookie.split(';');
    const sessionCookie = cookies.find(cookie => cookie.trim().startsWith('session='));
    return sessionCookie ? sessionCookie.split('=')[1].trim() : null;
  };

  const checkAuth = useCallback(async () => {
    console.log('[Auth] Checking authentication status');
    try {
      const sessionId = getSessionId();
      console.log('[Auth] Session ID from cookie:', sessionId);

      const headers: Record<string, string> = {
        'Content-Type': 'application/json'
      };

      if (sessionId) {
        headers['X-Session-ID'] = sessionId;
      }

      const response = await fetch(`${API_BASE}/api/auth/session`, {
        credentials: 'include',
        headers
      });

      console.log('[Auth] Check response received', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      const data = await handleResponse<{ data: { user: User } }>(response);
      console.log('[Auth] Check successful', { user: data.data.user });
      setUser(data.data.user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('[Auth] Check failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      });
      setUser(null);
      setIsAuthenticated(false);
      
      // Only redirect to login if we're not already there
      if (!location.pathname.startsWith('/login')) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  }, [navigate, location]);

  useEffect(() => {
    console.log('[Auth] Initializing auth check');
    checkAuth();
  }, [checkAuth]);

  const login = async (code: string) => {
    console.log('[Auth] Attempting login with code');
    try {
      const response = await fetch(`${API_BASE}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code }),
        credentials: 'include'
      });

      console.log('[Auth] Login response received', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      const data = await handleResponse<{ data: { user: User } }>(response);
      console.log('[Auth] Login successful', { user: data.data.user });

      // Get session ID from response header
      const sessionId = response.headers.get('X-Session-ID');
      console.log('[Auth] Session ID from response:', sessionId);

      setUser(data.data.user);
      setIsAuthenticated(true);
      navigate('/');
    } catch (error) {
      console.error('[Auth] Login failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      });
      throw error;
    }
  };

  const logout = async () => {
    console.log('[Auth] Attempting logout');
    try {
      const sessionId = getSessionId();
      console.log('[Auth] Session ID for logout:', sessionId);

      const headers: Record<string, string> = {
        'Content-Type': 'application/json'
      };

      if (sessionId) {
        headers['X-Session-ID'] = sessionId;
      }

      const response = await fetch(`${API_BASE}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        headers
      });

      console.log('[Auth] Logout response received', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries())
      });

      await handleResponse(response);
      console.log('[Auth] Logout successful');
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('[Auth] Logout failed', {
        error: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined
      });
      throw error;
    }
  };

  return {
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    checkAuth
  };
}