import { useQuery } from '@tanstack/react-query';

export function VersionInfo() {
  const frontendVersion = import.meta.env.VITE_FRONTEND_VERSION || 'dev';
  
  const { data: backendVersion } = useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_WORKER_URL}/api/version`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch version');
      }
      const data = await response.json();
      return data.data.version;
    },
    retry: false,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="fixed bottom-2 right-2 text-xs text-muted-foreground">
      <span title="Frontend Version">f:{frontendVersion}</span>
      <span className="mx-1">|</span>
      <span title="Backend Version">w:{backendVersion || 'dev'}</span>
    </div>
  );
} 